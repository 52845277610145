import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { rgba } from 'polished'
import React, { useMemo, useState } from 'react'
import { StructuredText } from 'react-datocms'
import { Helmet } from 'react-helmet'

import DatoLink from '../components/DatoLink'
import InternalLink from '../components/InternalLink'
import Layout from '../components/Layout'
import LightboxLink from '../components/LightboxLink'
import ProductFilters from '../components/ProductFilters'
import ProductHighlights from '../components/ProductHighlights'
import ProductImage from '../components/ProductImage'
import Seo from '../components/Seo'
import ShareButton from '../components/ShareButton'
import { baseGrid, button, inlineLinkBack, mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import { filtersToParams } from '../utils/helpers'

export const data = graphql`
  query ($name: String!) {
    product: datoCmsProduct(name: { eq: $name }) {
      name
      doorStyle {
        name
      }
      details {
        title
        detail {
          value
          links {
            ... on DatoCmsLightbox {
              ...LightboxFragment
            }
          }
        }
      }
      fallbackProductImage {
        gatsbyImageData(
          imgixParams: { q: 70 }
          width: 1200
          breakpoints: [360, 480, 720, 960]
        )
        seoImage: url(imgixParams: { q: 70, w: "400" })
      }
      productImages {
        woodType {
          name
        }
        stainedImages {
          finish: stain {
            name
          }
          image {
            gatsbyImageData(
              imgixParams: { q: 70 }
              width: 1200
              breakpoints: [360, 480, 720, 960]
            )
          }
        }
        paintedImages {
          finish: paint {
            name
          }
          image {
            gatsbyImageData(
              imgixParams: { q: 70 }
              width: 1080
              breakpoints: [360, 480, 720, 960]
            )
          }
        }
      }
    }
    page: datoCmsProductPageTemplate {
      productCta {
        ... on DatoCmsLightboxLink {
          ...LightboxLinkFragment
        }
        ... on DatoCmsInternalLink {
          ...InternalLinkFragment
        }
        ... on DatoCmsExternalLink {
          ...ExternalLinkFragment
        }
      }
      productHighlights {
        ... on DatoCmsProductHighlight {
          __typename
          heading
          bodyNode {
            childMarkdownRemark {
              html
            }
          }
          links {
            ... on DatoCmsLightboxLink {
              ...LightboxLinkFragment
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkFragment
            }
            ... on DatoCmsExternalLink {
              ...ExternalLinkFragment
            }
          }
          image {
            gatsbyImageData(
              imgixParams: { q: 75, ar: "5:3", fit: "crop" }
              width: 960
            )
            alt
            focalPoint {
              x
              y
            }
          }
        }
        ... on DatoCmsProductHighlightVideo {
          __typename
          heading
          bodyNode {
            childMarkdownRemark {
              html
            }
          }
          links {
            ... on DatoCmsLightboxLink {
              ...LightboxLinkFragment
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkFragment
            }
            ... on DatoCmsExternalLink {
              ...ExternalLinkFragment
            }
          }
          video {
            url
            thumbnailUrl
          }
        }
      }
    }
    productsOverview: datoCmsProductsOverview {
      slug
    }
  }
`

const ProductPage = ({ data }) => {
  const { product, page, productsOverview } = data

  const [filters, setFilters] = useState({
    woodType: null,
    woodFinish: null,
    doorStyles: [],
  })

  const queryString = useMemo(() => filtersToParams(filters), [filters])

  const productStyle = css`
    ${baseGrid}
    margin-bottom: var(--gutter-lg);
    h1 {
      font-size: var(--fs-48);
      margin-top: 0.125em;
    }
  `

  const imageWrapperStyle = css`
    grid-column: 2 / span 5;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: sticky;
    align-self: flex-start;
    top: var(--gutter-sm);
    ${mq().ms} {
      grid-column: 2 / -2;
      border-bottom: 1px solid ${rgba(colors.grayLight, 0.5)};
      padding-bottom: var(--gutter-lg);
      position: relative;
      justify-content: center;
    }
  `
  const imageStyle = css`
    img {
      width: auto;
      max-width: 100%;
      max-height: calc(100vh - 2 * var(--gutter-sm));
      ${mq().ms} {
        max-height: calc(60vh - 2 * var(--gutter-sm));
      }
      &[data-placeholder-image] {
        width: 100%;
        height: 100%;
      }
    }
  `
  const productAttributesStyle = css`
    grid-column: 7 / span 7;
    margin-left: var(--gutter-md);
    ${mq().ms} {
      grid-column: 2 / -2;
      margin-left: 0;
      margin-top: var(--gutter-lg);
    }
  `
  const detailsStyle = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: calc(var(--gutter-sm) + 0.5em);
    grid-row-gap: 1.5rem;
    margin-bottom: 4rem;
    font-size: var(--fs-15);
    h2 {
      font-size: inherit;
      font-family: var(--sans-serif);
      font-weight: 700;
      margin: 0 0 0.25em;
    }
    p {
      font-size: inherit;
      margin: 0;
      a {
        color: ${colors.green};
        font-weight: 500;
      }
    }
    ${mq().ml} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${mq().s} {
      grid-template-columns: 1fr;
    }
  `
  const ctaStyle = css`
    border-top: 1px solid ${rgba(colors.grayLight, 0.5)};
    padding-top: 3rem;
    display: flex;
    align-items: stretch;
    > a {
      ${button}
      margin-right: var(--gutter-sm);
    }
  `
  const backLinkStyle = css`
    padding: var(--gutter-lg) var(--margin-outer)
      calc(var(--gutter-sm) + 1rem);
    > a {
      ${inlineLinkBack}
      font-size: var(--fs-15);
    }
  `
  const filtersStyle = css`
    ul {
      ${mq().s} {
        --item-size: 3.5em;
      }
    }
  `
  return (
    <Layout>
      <Seo
        title={`${product.name} Cabinet with ${product.doorStyle.name} Doors`}
        description={`High-quality customizable ${product.name} cabinet with ${product.doorStyle.name} style doors. See photo & wood options: Maple, Alder, Cherry, Hickory, Oak or Painted Maple.`}
        image={product.fallbackProductImage.seoImage}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: product.name,
            image: product.fallbackProductImage.seoImage,
            description: `High-quality customizable ${product.name} cabinet with ${product.doorStyle.name} style doors. See photo & wood options: Maple, Alder, Cherry, Hickory, Oak or Painted Maple.`,
            brand: {
              '@type': 'Brand',
              name: 'Countryside Cabinets',
            },
          })}
        </script>
      </Helmet>
      <div css={backLinkStyle}>
        <InternalLink
          data={{
            page: productsOverview,
            linkText: 'All Cabinets',
          }}
          suffix={`/?${queryString}`}
        />
      </div>
      <section css={productStyle}>
        {filters.woodType && filters.woodFinish && (
          <div css={imageWrapperStyle}>
            <ProductImage
              imageCss={imageStyle}
              product={product}
              woodType={filters.woodType}
              woodFinish={filters.woodFinish}
              fullSize
            />
          </div>
        )}
        <section css={productAttributesStyle}>
          <h1>{product.name}</h1>
          <section css={detailsStyle}>
            <div>
              <h2>Door Style</h2>
              <p>{product.doorStyle.name}</p>
            </div>
            {product.details.map((detail, i) => (
              <div key={i}>
                <h2>{detail.title}</h2>
                <StructuredText
                  data={detail.detail}
                  renderLinkToRecord={({ record, children }) => {
                    switch (record.__typename) {
                      case 'DatoCmsLightbox':
                        return (
                          <LightboxLink
                            data={{
                              lightbox: record,
                              linkText: children,
                            }}
                          />
                        )
                      default:
                        return null
                    }
                  }}
                />
              </div>
            ))}
          </section>
          <ProductFilters
            filterCss={filtersStyle}
            showDescription
            hideDoorStyles
            onFilterChange={newFilters => setFilters(newFilters)}
          />
          <section css={ctaStyle}>
            <DatoLink data={page.productCta[0]} />
            <ShareButton inline text="Share" />
          </section>
        </section>
      </section>
      <ProductHighlights highlights={page.productHighlights} />
    </Layout>
  )
}

export default ProductPage
