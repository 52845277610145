import { css } from '@emotion/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { absoluteFill, baseGrid, inlineLink, mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import { focalPoint } from '../utils/helpers'
import AnimateIn from './AnimateIn'
import DatoLink from './DatoLink'
import ParallaxTranslate from './ParallaxTranslate'
import VideoPlayer from './VideoPlayer'

const ProductHighlights = ({ highlights }) => {
  const sectionStyle = css`
    background-color: ${colors.greenLighter};
    padding: var(--gutter-sm) 0 var(--gutter-md);
  `
  const highlightWrapStyle = css`
    margin: var(--gutter-lg) 0;
  `
  const highlightStyle = css`
    ${baseGrid}
    ${mq().ms} {
      grid-template-rows: auto var(--gutter-lg) auto;
    }
  `
  const textboxWrapperStyle = css`
    grid-row: 1 / 2;
    grid-column-end: span 6;
    align-self: flex-start;
    z-index: 1;
    margin: var(--gutter-lg) 0 var(--gutter-md);
    --translate-factor: -50;
    .text-left & {
      grid-column-start: 3;
    }
    .text-right & {
      grid-column-start: 7;
    }
    ${mq().ms} {
      --translate-factor: -10;
      grid-row: 2 / 4;
      grid-column-end: span 11;
      margin-top: 0;
      .text-left & {
        grid-column-start: 2;
      }
      .text-right & {
        grid-column-start: 3;
      }
    }
    ${mq().s} {
      grid-column-end: span 12;
      .text-right & {
        grid-column-start: 2;
      }
    }
  `
  const textboxStyle = css`
    background-color: white;
    padding: var(--gutter-md) var(--gutter-md) var(--gutter-sm);
    .text-left & {
      padding-right: calc(var(--gutter-md) + var(--column-width));
    }
    .text-right & {
      padding-left: calc(var(--gutter-md) + var(--column-width));
    }
    font-size: var(--fs-16);
    h2 {
      font-size: var(--fs-36);
      margin: 0 0 0.75em;
    }
    p,
    li {
      line-height: 1.5;
    }
    > div {
      margin-bottom: 1.5em;
    }
    ${mq().ms} {
      padding: var(--gutter-xlg) var(--gutter-mlg) var(--gutter-md);
      h2 {
        margin-top: 0.25em;
      }
    }
  `
  const linkStyle = css`
    ${inlineLink}
    font-size: var(--fs-15);
    margin-top: 0.75em;
    margin-bottom: 1.5em;
  `
  const graphicStyle = css`
    grid-row: 1 / 2;
    grid-column-end: span 7;
    display: flex;
    align-items: flex-start;
    z-index: 2;
    position: relative;
    .text-left & {
      grid-column-start: 8;
    }
    .text-right & {
      grid-column-start: 1;
    }
    ${mq().ms} {
      grid-row: 1 / 3;
      grid-column-end: span 10;
      height: 0;
      padding-bottom: 75%;
      .text-left & {
        grid-column-start: 5;
      }
    }
    ${mq().s} {
      grid-column-end: span 12;
      .text-left & {
        grid-column-start: 3;
      }
    }
  `
  const videoGraphicStyle = css`
    ${mq().ms} {
      height: auto;
      padding-bottom: 0;
    }
  `
  const imageStyle = css`
    display: flex;
    ${mq().ms} {
      ${absoluteFill}
    }
    img {
      width: 100%;
      height: 100%;
    }
  `
  return (
    <section css={sectionStyle}>
      {highlights.map((highlight, i) => {
        const { image, video, heading, bodyNode, links } = highlight
        return (
          <AnimateIn
            wrapperCss={highlightWrapStyle}
            innerCss={highlightStyle}
            key={i}
            className={i % 2 ? 'text-right' : 'text-left'}
          >
            <ParallaxTranslate css={textboxWrapperStyle} fromBack>
              <div css={textboxStyle}>
                <h2>{heading}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: bodyNode.childMarkdownRemark.html,
                  }}
                />
                {links.map((link, i) => (
                  <DatoLink key={i} data={link} css={linkStyle} />
                ))}
              </div>
            </ParallaxTranslate>
            {image && (
              <div css={graphicStyle}>
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={image.alt || ''}
                  objectPosition={focalPoint(image.focalPoint)}
                  css={imageStyle}
                />
              </div>
            )}
            {video && (
              <div css={[graphicStyle, videoGraphicStyle]}>
                <VideoPlayer video={video} />
              </div>
            )}
          </AnimateIn>
        )
      })}
    </section>
  )
}

export default ProductHighlights
